export const aegeanNums = {
  "1": "\u{10107}",
  "2": "\u{10108}",
  "3": "\u{10109}",
  "4": "\u{1010A}",
  "5": "\u{1010B}",
  "6": "\u{1010C}",
  "7": "\u{1010D}",
  "8": "\u{1010E}",
  "9": "\u{1010F}",
  "10": "\u{10110}",
  "20": "\u{10111}",
  "30": "\u{10112}",
  "40": "\u{10113}",
  "50": "\u{10114}",
  "60": "\u{10115}",
  "70": "\u{10116}",
  "80": "\u{10117}",
  "90": "\u{10118}",
  "100": "\u{10119}",
  "200": "\u{1011A}",
  "300": "\u{1011B}",
  "400": "\u{1011C}",
  "500": "\u{1011D}",
  "600": "\u{1011E}",
  "700": "\u{1011F}",
  "800": "\u{10120}",
  "900": "\u{10121}",
  "1000": "\u{10122}",
  "2000": "\u{10123}",
  "3000": "\u{10124}",
  "4000": "\u{10125}",
  "5000": "\u{10126}",
  "6000": "\u{10127}",
  "7000": "\u{10128}",
  "8000": "\u{10129}",
  "9000": "\u{1012A}",
  "10000": "\u{1012B}",
  "20000": "\u{1012C}",
  "30000": "\u{1012D}",
  "40000": "\u{1012E}",
  "50000": "\u{1012F}",
  "60000": "\u{10130}",
  "70000": "\u{10131}",
  "80000": "\u{10132}",
  "90000": "\u{10133}",
};
